import { defineStore } from "pinia";
import {
  type UserBecomeSellerInterface,
  SellerStatusEnum,
} from "~/models/seller";
import { useSellerApi } from "@/composables/api/useSellerApi";
import { useUserStore } from "@/stores/useUserStore";
import Delivery from "~/interfases/delivery/delivery";
import CustomDelivery from "~/interfases/delivery/custom-delivery";
import type { PaymentMethod } from "~/interfases/payment-method/payment-method";

export const useSellerStore = defineStore("seller-store", () => {
  const client = useSanctumClient();

  const sellerApi = useSellerApi();
  const userStore = useUserStore();
  const loading = ref(false);
  const sellerTypes = ref([]);
  const sellersList = ref([]);
  const seller = ref(null);
  const delivery = ref<Delivery | null>(null);
  const deliveries = ref<Delivery[]>([]);
  const customDelivery = ref<CustomDelivery | null>(null);
  const customDeliveries = ref<CustomDelivery[]>([]);
  const paymentMethods = ref<PaymentMethod[]>([]);
  const paymentMethod = ref<PaymentMethod | null>(null);
  const customPaymentMethods = ref<PaymentMethod[]>([]);
  const customPaymentMethod = ref<PaymentMethod | null>(null);

  const isSeller = computed(
    () => userStore?.user?.company?.status === SellerStatusEnum.APPROVED
  );

  const getSellerId = computed(() => userStore.user?.id);

  const getSellerTypes = async () => {
    const { data: response } = await sellerApi.getSellerTypes();

    sellerTypes.value = response;
  };

  const becomeSeller = async (data: UserBecomeSellerInterface) => {
    // !!! DONT USE LOADERS IN STORES. ITS ANTI-PATTERN !!!
    try {
      loading.value = true;
      await sellerApi.becomeSeller(userStore?.user?.id, data);
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const getAllSellers = async () => {
    // !!! DONT USE LOADERS IN STORES. ITS ANTI-PATTERN !!!
    try {
      loading.value = true;
      const { data: response } = await sellerApi.getAllSellers();
      sellersList.value = response.data;
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const getSellerById = async (id: number) => {
    // !!! DONT USE LOADERS IN STORES. ITS ANTI-PATTERN !!!
    try {
      loading.value = true;
      const { data: response } = await sellerApi.getSellerById(id);
      seller.value = response.data;
    } catch (error: any) {
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const attachDelivery = async (data: { id: number; comment: string }) => {
    const response = await client(
      `/api/sellers/${getSellerId.value}/deliveries/attach`,
      {
        method: "POST",
        body: data,
      }
    );

    return response.data;
  };

  //================================================================================================
  // Deliveries
  //================================================================================================
  const fetchDeliveries = async () => {
    const response = await client(
      `/api/sellers/${getSellerId.value}/deliveries`
    );

    deliveries.value = response.data;

    return response.data;
  };

  const fetchDelivery = async (id: number) => {
    const response = await client(
      `/api/sellers/${getSellerId.value}/deliveries/${id}`
    );

    delivery.value = response.data;

    return response.data;
  };

  // const saveDelivery = async (deliveries: Delivery[]) => {
  //   await client(`/api/sellers/${getSellerId.value}/seller-deliveries/sync`, {
  //     method: "POST",
  //     body: deliveries,
  //   });
  // };

  const syncDeliveryTypes = async (deliveryId: number, types: number[]) => {
    await client(
      `/api/sellers/${getSellerId.value}/deliveries/${deliveryId}/delivery-types/sync`,
      {
        method: "POST",
        body: types,
      }
    );
  };

  //================================================================================================
  // Custom deliveries
  //================================================================================================
  const fetchCustomDeliveries = async () => {
    const response = await client(
      `/api/sellers/${getSellerId.value}/custom-deliveries`
    );

    customDeliveries.value = response.data;

    return response.data;
  };

  const fetchCustomDelivery = async (id: number) => {
    const response = await client(
      `/api/sellers/${getSellerId.value}/custom-deliveries/${id}`
    );

    customDelivery.value = response.data;

    return response.data;
  };

  const createCustomDelivery = async (data: any) => {
    const response = await client(
      `/api/sellers/${getSellerId.value}/custom-deliveries`,
      {
        method: "POST",
        body: data,
      }
    );

    customDeliveries.value?.push(response.data);

    return response.data;
  };

  const updateCustomDelivery = async (id: number, data: any) => {
    await client(`/api/sellers/${getSellerId.value}/custom-deliveries/${id}`, {
      method: "PUT",
      body: data,
    });
  };

  const deleteCustomDelivery = async (id: number) => {
    await sellerApi.deleteCustomDelivery(getSellerId.value, id);

    customDeliveries.value = customDeliveries.value?.filter(
      (delivery: CustomDelivery) => delivery.id !== id
    );
  };

  //================================================================================================
  // Payment methods
  //================================================================================================
  const fetchPaymentMethod = async (id: number) => {
    const response = await client(
      `/api/sellers/${getSellerId.value}/payment-methods/${id}`
    );

    paymentMethod.value = response.data;

    return response.data;
  };

  const fetchPaymentMethods = async () => {
    const response = await client(
      `/api/sellers/${getSellerId.value}/payment-methods`
    );

    paymentMethods.value = response.data;

    return response.data;
  };

  const savePaymentMethods = async (data: any) => {
    await sellerApi.savePaymentMethods(getSellerId.value, data);
  };

  const savePaymentMethod = async (data: any) => {
    await sellerApi.savePaymentMethod(getSellerId.value, data);
  };

  const detachPaymentMethod = async (id: number) => {
    await client(
      `/api/sellers/${getSellerId.value}/payment-methods/${id}/detach`,
      {
        method: "DELETE",
      }
    );

    await fetchPaymentMethods();
  };

  //================================================================================================
  // Custom payment methods
  //================================================================================================
  const fetchCustomPaymentMethods = async () => {
    const response = await client(
      `/api/sellers/${getSellerId.value}/custom-payment-methods`
    );

    customPaymentMethods.value = response.data;

    return response.data;
  };

  const fetchCustomPaymentMethod = async (id: number) => {
    const response = await client(
      `/api/sellers/${getSellerId.value}/custom-payment-methods/${id}`
    );

    customPaymentMethod.value = response.data;

    return response.data;
  };

  const syncCustomPaymentMethods = async (data: any) => {
    await client(`/api/sellers/${getSellerId.value}/custom-payment-methods`, {
      method: "POST",
      body: data,
    });
  };

  const createCustomPaymentMethod = async (data: any) => {
    const response = await client(
      `/api/sellers/${getSellerId.value}/custom-payment-methods`,
      {
        method: "POST",
        body: data,
      }
    );

    customPaymentMethod.value = response.data;

    return response.data;
  };

  const updateCustomPaymentMethod = async (id: number, data: any) => {
    await client(
      `/api/sellers/${getSellerId.value}/custom-payment-methods/${id}`,
      {
        method: "PUT",
        body: data,
      }
    );
  };

  const deleteCustomPaymentMethod = async (id: number) => {
    await client(
      `/api/sellers/${getSellerId.value}/custom-payment-methods/${id}`,
      {
        method: "DELETE",
      }
    );

    customPaymentMethods.value = customPaymentMethods.value?.filter(
      (paymentMethod: PaymentMethod) => paymentMethod.id !== id
    );
  };

  return {
    getSellerId,
    loading,
    sellerTypes,
    sellersList,
    seller,
    isSeller,
    delivery,
    deliveries,
    customDelivery,
    customDeliveries,
    paymentMethods,
    customPaymentMethods,
    customPaymentMethod,
    becomeSeller,
    getSellerTypes,
    getAllSellers,
    getSellerById,
    syncDeliveryTypes,
    fetchDeliveries,
    fetchDelivery,
    fetchCustomDelivery,
    fetchCustomDeliveries,
    createCustomDelivery,
    updateCustomDelivery,
    deleteCustomDelivery,
    fetchPaymentMethod,
    fetchPaymentMethods,
    savePaymentMethods,
    savePaymentMethod,
    fetchCustomPaymentMethods,
    fetchCustomPaymentMethod,
    createCustomPaymentMethod,
    updateCustomPaymentMethod,
    deleteCustomPaymentMethod,
    syncCustomPaymentMethods,
    attachDelivery,
    detachPaymentMethod,
  };
});
